import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class userService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    requestGoogleAuthenticator() {
        let url = `${this.#api}/google-authenticator`
        return apiService.get(url);
    }

    activateGoogleAuthenticator(data) {
        let url = `${this.#api}/activate/google-authenticator`
        return apiService.post(url, data);
    }

    deActivateGoogleAuthenticator() {
        let url = `${this.#api}/deactivate/google-authenticator`
        return apiService.post(url);
    }


    checkVerificationEnabled(data) {
        let url = `${this.#api}/check/verification-enabled`
        return apiService.post(url, data);
    }


    verifyGoogleVerificationCode(data) {
        let url = `${this.#api}/verify/google-verification-code`
        return apiService.post(url, data);
    }

    validateEmail(data) {
        let url = `${this.#api}/validate-email`
        return apiService.post(url, data);
    }

    validatePhone(data) {
        let url = `${this.#api}/validate-mobile`
        return apiService.post(url, data);
    }

    verifyEmailVerificationCode(data) {
        let url = `${this.#api}/verify/email-verification-code`
        return apiService.post(url, data);
    }

    validateMidasId(midas_id) {
        let url = `${this.#api}/validate/midasId/${midas_id}`
        return apiService.get(url);
    }

    validateRegistratioNo(reg_no) {
        let url = `${this.#api}/validate/registration_no/${reg_no}`
        return apiService.get(url);
    }

    validateSymbolNo(symbol_id) {
        let url = `${this.#api}/validate/symbol_no/${symbol_id}`
        return apiService.get(url);
    }

    sendMobileVerificationCode() {
        let url = `${this.#api}/send/verification-code`
        return apiService.get(url);
    }


    getClassStats(data=null) {
        let url = `${this.#api}/class/stats`;
        let param = {
            params: data
        }
        return apiService.query(url, param);

    }


    verifyMobile(data) {
        let url = `${this.#api}/verify/mobile`
        return apiService.post(url, data);
    }

    register(data) {
        let url = `${this.#api}/register`
        return apiService.post(url, data);
    }

    update(id, data) {
            let url = `${this.#api}/user/${id}/update`
            return apiService.post(url, data);
    }

    updateUser(id, data) {
            let url = `${this.#api}/user/${id}`
            return apiService.put(url, data);
    }

    bulkUpdatePassword(data){
        let url = `${this.#api}/user/bulk-change-password`
        return apiService.post(url, data);
    }

    createByAdmin(data) {
        let url = `${this.#api}/create-user/by-admin`
        return apiService.post(url, data);
    }


    paginate(data, index = null) {
        let url = `${this.#api}/user`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }

    renew(userDetailId){
        let url = `${this.#api}/user/${userDetailId}/renew`
        return apiService.post(url);
    }

    verifyEmail(userDetailId){
        let url = `${this.#api}/user/${userDetailId}/verify-email`
        return apiService.post(url);
    }

    verifyPhone(userDetailId){
        let url = `${this.#api}/user/${userDetailId}/verify-phone`
        return apiService.post(url);
    }

    getStats(id) {
        let url = `${this.#api}/user/${id}/stats`
        return apiService.get(url);
    }

    getStatCounts(id){
        let url = `${this.#api}/user/${id}/stat/count`
        return apiService.get(url);
    }

    show(id) {
        let url = `${this.#api}/user/${id}`
        return apiService.get(url);
    }

    all() {
        let url = `${this.#api}/user/get/all`
        return apiService.get(url);
    }

    search(data={}){
        let url = `${this.#api}/user/search/all`;
        let param = {params: data};
        return apiService.query(url, param);
    }
    searchUserForChat(data={}){
        let url = `${this.#api}/user/search/for/chat`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    filter(data={}){
        let url = `${this.#api}/user/filter`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    getByTypes(data={}){
        let url = `${this.#api}/user/get-by-type`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    delete(id) {
        let url = `${this.#api}/user/${id}`
        return apiService.delete(url);
    }

    logged() {
        let url = `${this.#api}/is/logged`
        return apiService.get(url);
    }

    requesEmailVerificationCode(data) {
        let url = API_URL + 'user/request/verification-code'
        return apiService.post(url, data);
    }

    requesRegistrationEmailVerificationCode(data) {
        let url = API_URL + 'user/resend/register/verification'
        return apiService.post(url, data);
    }

    getTeachers() {
        let url = `${this.#api}/teacher/all`
        return apiService.get(url);
    }

    assignRole(id, data) {
        let url = `${this.#api}/${id}/assign-role`
        return apiService.post(url, data);
    }

    removeRole(id, data) {
        let url = `${this.#api}/${id}/remove-role`
        return apiService.post(url, data);
    }

    userRoles(id) {
        let url = `${this.#api}/${id}/roles`
        return apiService.get(url);
    }

    getStudentsByAcademicYearProgramAndSemester(academicYearId, programId, gradeId) {
        let url = `${this.#api}/student/${academicYearId}/program/${programId}/grade/${gradeId}`
        return apiService.get(url);
    }

    importUser(data) {
        let url = `${this.#api}/import-student`;
        return apiService.post(url, data);
    }

    importNewUser(data) {
        let url = `${this.#api}/import-new-student`;
        return apiService.post(url, data);
    }

    activateZoom(data) {

        let url = `${this.#api}/activate-zoom-account`;
        return apiService.post(url, data);
    }

    toggleZoomAccount(id) {
        let url = `${this.#api}/zoom-account/${id}/toggle`
        return apiService.get(url);
    }

    getGroupedPermission() {
        let url = `${this.#api}/by/group`
        return apiService.get(url);
    }

    getZoomAccountsTeachers(id) {
        let url = `${this.#api}/zoom-account/active/all`
        return apiService.get(url);
    }

    getAllActiveTeachers(id) {
        let url = `${this.#api}/teacher/active/all`
        return apiService.get(url);
    }


    checkZoomAccount(id) {
        let url = `${this.#api}/check-zoom-account/${id}`
        return apiService.get(url);
    }

    getAllUserByIds(data) {
        let url = `${this.#api}/user/selected/all`;
        return apiService.post(url, data);
    }

    getTeacherByProgram(programId){
        let url = `${this.#api}/user/program/${programId}/teachers/all`;
        return apiService.get(url);
    }

    async getUserPermission(userId){
        let url = `${this.#api}/user/permission/list`
        return await apiService.get(url);
    }
    getAllDevice(data={}){
        let url=`${this.#api}/user/get-all-devices`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    createDevice(data) {
        let url = `${this.#api}/user/create-device`
        return apiService.post(url, data)
    }
    getAllGraduatedStudent(data={},index=null){
        let url=`${this.#api}/user/get-all-graduated-students`;
        if (index)
            data.page = index;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    exportStaff(data={}){
        let url = `${this.#api}/staff-export-csv`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    exportTeacher(data={}){
        let url = `${this.#api}/teacher-export-csv`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    exportGraduatedStudent(data={}){
        let url = `${this.#api}/graduated-student-export-csv`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    searchGraduationStudents(data={}){
        let url = `${this.#api}/user/search/graduation-students`;
        let param = {params: data};
        return apiService.query(url, param);
    }

    getParentsVisitHistory(id){
        let url = `${this.#api}/user/${id}/parents-visit-history`;
        return apiService.get(url);
    }
    getAllRFIDUsers(data={},index=null){
        let url = `${this.#api}/user/get-all-rfid-users`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getAssignedPrograms(userId){
        let url = `${API_URL}user/${userId}/assigned/programs`;
        return apiService.get(url);
    }

    syncPrograms(userId,data){
        let url = `${this.#api}/user/${userId}/sync-user-programs`;
        return apiService.post(url, data);
    }

    importTeacher(data) {
        let url = `${this.#api}/import-teacher`;
        return apiService.post(url, data);
    }

    importStaff(data) {
        let url = `${this.#api}/import-staff`;
        return apiService.post(url, data);
    }
}
